
import { Component, Vue } from 'vue-property-decorator'
import { apiCommissLists, apiApplyPass, apiApplyRefuse } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsPagination,
    ExportData,
    LsDialog
  }
})
export default class DesignerOrder extends Vue {
    /** S Data **/
    form = {
      designer_id: '' // 用户信息
    }

    // 日期选择器数据
    timeForm = []

    // 设置用户标签弹出框
    dialogVisible = false
    // 选中用户标签
    labelValue = []
    // 用户选择框数据
    userLevelList = {}
    userLabelList = {}
    sourceList = {}
    // 分页查询
    pager: RequestPaging = new RequestPaging()
    // 被选中的用户id
    multipleSelection = []
    /** E Data **/

    /** S Methods **/
    apiCommissLists = apiCommissLists // 传递给导出组件的api

    // 选中的用户触发事件
    handleSelectionChange (val: any) {
      this.multipleSelection = val
    }

    // 查询按钮
    query () {
      this.pager.page = 1
      this.getUserList()
    }

    // 获取用户列表数据
    getUserList () {
      this.pager.request({
        callback: apiCommissLists,
        params: {
          ...this.form
        }
      })
    }

    // 重置按钮
    onReset () {
      this.form = {
        designer_id: '' // 用户信息
      }
      this.timeForm = []
      this.getUserList()
    }

    // 用户详情
    DetailsClick (item: any) {
      // this.$router.push({
      //     path: '/shop/order_details',
      //     query: {
      //         id: item.id
      //     }
      // })
    }

    // 冻结用户
    handleFrozen (userInfo: any, val: any) {
      console.log(userInfo, val)
      const { id } = userInfo
      this.setUserInfo(id, val)
    }

    // 设置用户信息
    setUserInfo (id: number | string, value: any) {
      if (value == 1) {
        apiApplyPass({
          id: id
        }).then(res => {
          this.getUserList()
        })
      } else {
        apiApplyRefuse({
          id: id
        }).then(res => {
          this.getUserList()
        })
      }
    }

    // 批量冻结
    handleBatchFrozen (value: any) {
      const userIds = this.multipleSelection.map((item: any) => item.id)
      this.setUserInfo(userIds.join(','), value)
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.getUserList()
    }
    /** E Life Cycle **/
}
